import axios from 'axios';

class ToolService {
  list() {
    return axios.get('/tools').then (
      resp => {
        return {
          data: resp.data['hydra:member'],
          totalItems: resp.data['hydra:totalItems']
        }; 
      });
  }

  get(id) {
    return axios.get('/tools/' + id).then (resp => resp.data);
  }
}

export default new ToolService();